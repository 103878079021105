<template>
  <div id="detalle-capacidadpago">

    <div class="container">
      <h2 class="text-center d-block">Capacidad de pago</h2>
      <h5 class="text-center d-block">Solicitud: {{ solicitud.ServiciosOriginacion.id }}</h5>
      <h6 class="text-center d-block">Tipo Solicitud: {{ solicitud.tipo_solicitud }}</h6>
      <div class='comportamiento col-md-12'>
        <div class="row">
          <div class="col-md-12">
            <div class="text-center" style="overflow:auto">
              <h4> Depósitos </h4>
              <div id="chartDepositos" width="1000px" height="300px" style="width:1000px;height:300px;"></div>
            </div>
          </div>

          <div class="col-md-12 mb-3">
            <div class="title">
              <div class="business-heading">
                <h4> Resumen Cuentas</h4>
              </div>
            </div>
            <div class='comportamiento col-md-12'>
              <div class="content-tabla">
                <div class="generales-data">
                  <span class="heanding-mobile data-green">
                    Monto solicitado
                  </span>
                  <span class="col-md-6 text-right data-green">
                    ${{ formatPrice(solicitud.ServiciosOriginacion['importe_solicitado']) }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Pago requerido
                  </span>
                  <span class="col-md-6 text-right">
                    ${{ formatPrice(solicitud.variablesUnykoo.BpbDeudaMensual) }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Total monto propuesto
                  </span>
                  <span class="col-md-6 text-right">
                    ${{formatPrice(solicitud.Ocr.montoPropuestoTotal)}}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Ingresos totales promedio
                  </span>
                  <span class="col-md-6 text-right">
                    ${{formatPrice(solicitud.Ocr.ingresosTotal)}}
                  </span>
                </div>
              </div>
            </div>
            <hr/>
          </div>
          <div class="col-md-12">
            <ul class="nav nav-tabs nav-justified">
            
              <li class="nav-item" v-for="(ocr, index) of solicitud.Ocr.cuentas" :key="ocr.id" >
                <a class="nav-link" @click.prevent="setActive(index)" :class="{ active: isActive(index) }" href="#">Cuenta-{{index+1}}</a>
              </li>
            </ul>
            <div class="tab-content py-3" id="myTabContent">
              <div class="tab-pane fade" :class="{ 'active show': isActive(index) }"  v-for="(ocr, index) of solicitud.Ocr.cuentas" :key="ocr.id" >
                
                <div class="row">
                  <div class="col-md-6">
                    <div class="content-tabla">
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Balance diario promedio
                        </span>
                        <span class="col text-right">
                          ${{ formatPrice(ocr['average_daily_balance']) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Balance final (última vez)
                        </span>
                        <span class="col text-right">
                          ${{ formatPrice(ocr['ending_balance']) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Monto de NSF's
                        </span>
                        <span class="col text-right">
                          ${{ formatPrice(ocr['number_of_nsfs']) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Número de días negativos
                        </span>
                        <span class="col text-right">
                          {{ ocr['number_of_negative_days'] }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Ticket promedio (operación)
                        </span>
                        <span class="col text-right">
                          ${{ formatPrice(ocr['transaction_ticket_size']) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Volatilidad monto operaciones
                        </span>
                        <span class="col text-right">
                          {{ ocr['transaction_size_volatility'] }}%
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Número de operaciones
                        </span>
                        <span class="col text-right">
                          {{ ocr['number_of_transactions'] }}
                        </span>
                      </div>
                    </div>
                    
                    <hr />

                    <h4 class="text-center">Mensaje advertencia</h4>
                    <div v-if="ocr.mensajes.length == 0">
                      <p class="text-center alert-success"> No hay mensajes de advertencia.</p>
                    </div>
                    <div v-else :key="ocrMensaje.index" v-for="ocrMensaje in ocr.mensajes" class="content-tabla ">
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Fecha
                        </span>
                        <span class="col text-right">
                          {{ ocrMensaje.fecha }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Balance
                        </span>
                        <span class="col text-right">
                          {{ ocrMensaje.balance }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Calculado
                        </span>
                        <span class="col text-right">
                          {{ ocrMensaje.calculado }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Mensaje
                        </span>
                        <span class="col text-right alert-danger">
                          {{ ocrMensaje.mensaje }}
                        </span>
                      </div>
                      <hr />
                    </div>
                  </div>
                  
                  <hr class="d-block d-sm-none" />

                  <div class="col-md-6">
                    <div class="content-tabla back-green">
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Monto solicitado
                        </span>
                        <span class="col text-right">
                          ${{ formatPrice(solicitud.ServiciosOriginacion['importe_solicitado']) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Pago requerido
                        </span>
                        <span class="col text-right">
                          ${{ formatPrice(solicitud.variablesUnykoo.BpbDeudaMensual) }}
                        </span>
                      </div>
                    </div>
                    <div class="content-tabla">
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Ingresos
                        </span>
                        <span class="col text-right">
                          ${{ formatPrice(ocr.income) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Máximo
                        </span>
                        <span class="col text-right">
                          ${{ formatPrice(ocr['maximum_deposit']) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Mínimo
                        </span>
                        <span class="col text-right">
                          ${{ formatPrice(ocr['minimum_deposit']) }}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div class=" content-tabla">
                      <div class="desvEstandar">
                        <div class="generales-data">
                          <span class="heanding-mobile">
                            Desv. Est.
                          </span>
                          <span class="col text-right">
                            {{ ocr.transaction_size_volatility }} %
                          </span>
                        </div>
                        <div class="generales-data">
                          <span class="heanding-mobile">
                            Calificacion
                          </span>
                          <span class="col text-right">
                            {{ desviacionEst(ocr.transaction_size_volatility) }} 
                          </span>
                        </div>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Pago posible
                        </span>
                        <span class="col text-right">
                          ${{ formatPrice(ocr.pagoPosible) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Monto propuesto
                        </span>
                        <span class="col text-right">
                          ${{ formatPrice(ocr.montoPropuesto) }}
                        </span>
                      </div>
                    </div>
                    <div class=" content-tabla back-green">
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Payment to income
                        </span>
                        <span class="col text-right">
                          {{ (ocr.pagoIngresos * 100) }} %
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          
        </div>
      </div>

    </div>
    <div v-if="cesion_creditos">
      <VotacionCesionCredito/>
    </div>
    <div v-else>
      <Votacion/>
    </div>
  </div>
</template>

<script>
import api from '@/apps/comite/api/scoring'
import gchart from '@/helpers/gcharts'
import Votacion from '@/apps/comite/pages/Votacion'
import VotacionCesionCredito from '@/apps/comite/pages/VotacionCesionCreditos'

export default {
  name: 'capacidad-pago',
  components: {
    Votacion,
    VotacionCesionCredito,
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      depositosData: [] ,
      depositosOptions: {
        chart: {
          title: 'Depositos',
          subtitle: ' ',
          hAxis: {
            title: 'Fechas',
            titleTextStyle: {
              color: '#FF0000',            
            },
            
            slantedText:true,
            slantedTextAngle:45,
            
          },
          width: 900,
        }
      },
      showChart: false
      ,solicitud: null
      ,activeItem: 0
      ,cesion_creditos: false,
    }
  },

  created: function() {
    this.solicitud = this.$store.state.solicitud;    
  },

  async mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    });

    await gchart();
    this.chartData();

    if(this.$auth.getUser().roles.comite_ver_creditos_cedidos){
      this.cesion_creditos = true
    }
  },

  methods: {
    isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    formatPrice(value) {
        let val = (value/1).toFixed(0).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    async chartData() {
      let depositosData = await api.datos_chart(this.solicitud.solicitud.id);
      depositosData = depositosData.data;
      await gchart();
      google.charts.load('current', {packages: ['corechart']});
      google.charts.setOnLoadCallback(() => {

        let chart = new google.visualization.DataTable();
        chart.addColumn('string', 'Fechas');
        chart.addColumn('number', 'Depósitos');

        let chartRows = [];
        let numItems = depositosData.Running_Daily_Balance ? depositosData.Running_Daily_Balance.Date.length : 0;
        for(let i=0; i<numItems; i++) {
          chartRows.push([depositosData.Running_Daily_Balance.Date[i], (depositosData.Running_Daily_Balance.Total[i])]);
        }

        chartRows.sort(function(a, b) {
          let fechaA = a[0].split('/');
          let fechaB = b[0].split('/');
          return new Date(fechaA[2], (fechaA[0]-1), fechaA[1]) - new Date(fechaB[2], (fechaB[0]-1), fechaB[1]);
        })

        chart.addRows(chartRows);

        let options = {
          hAxis: {
            title: 'Montos'
            // ,ticks: [0, .3, .6, .9, 1]
          },
          vAxis: {
            title: 'Fecha'
            ,minValue: 0
          }
        };

        let objChart = new google.visualization.AreaChart(document.getElementById('chartDepositos'));
        objChart.draw(chart, options);
      });
    },
    
    desviacionEst(desviacionEstandar){      
      const desvEst = parseFloat(desviacionEstandar)
      let resultado = " ";
                  
      if (desvEst <= 25 && desvEst !== null){
        resultado = "Bueno";
      } else if(desvEst >= 25 && desvEst <= 50) {
        resultado = "Regular";
      } else if(desvEst > 50) {
        resultado = "Malo";
      } return resultado;
    },
    
    variablesAvatar(resultadoProspecto){
      resultadoProspecto=parseFloat(resultadoProspecto);
      
      let resultado = " ";  
      
      if (resultadoProspecto <= 4 && resultadoProspecto !== null){
        resultado = "(+.1)";
      } else if(resultadoProspecto >= 4.1 && resultadoProspecto <= 6.5) {
        resultado = "(0)";
      } else if(resultadoProspecto > 6.5){
        resultado = "(-.1)";
      } return resultado;
    }
  },

  computed: {
    getChartData(){
      return this.depositosData
    },

    mensajesCapacidad(){
      console.log(this.ocr.mensajes[0].fecha);
      console.log(this.ocr.mensajes.length);
      
      return this.ocr.mensajes.length == 0
    }
  },

  watch: {
    tab: function(val, oldVal) {
      // console.log('state', val);

      if (val == '3') {
        setTimeout(() => {
          this.chartData();
        }, 500);
      }
    }
  }

}

</script>

<style lang="scss" scoped>
  .nav-item .nav-link.active,
  .show>.nav-item .nav-link{
      background: $color-blue;
      color: white;
  }
  .title{
    margin-bottom: 1rem;
    .business-heading{
      background-color: $color-blue;
      padding: 1rem;
      margin: 1rem 0;
      
      h4 {
        color: $white;
      }
      
      @include mq-min(tablet){
        padding: 2% 0 1% 6%;
      }
    }
    .subtitles{
      background-color: $color-blue;
      padding: 1rem;
      margin: 1rem 0;
      
      h6 {
        color: $white;
      }
      
      @include mq-min(tablet){
        padding: 1% 1% 1% 1%;
      }
    }
  }
  #detalle-capacidadpago {
    background-color: $white;
    // min-height: 100vh; 
    margin-bottom: 100px;
    
    .content-tabla {
      color: $text-primary;
      
      .generales-data {
        display: flex;
        align-items: center;
        span{
          color: $text-primary;
        }
        .heanding-mobile {
          padding-left: 0;
          width: 75%;
        }
        .text-right {
          font-weight: 700;
          padding-right: 0;
        }
      }
    }
    @include mq-min(tablet){
     min-height: 49vh; 
    }
    .back-green {
      background-color: $color-green;
      padding: 0.5rem 0.25rem;
      .generales-data{
        span{
          color: $white;
        }
        &:nth-of-type(even){
          background: transparent;
        }
      }
    }
  }
  
 h2 {
    color: $text-primary;
    padding: 1rem;
  }
  
  #chartDepositos {
    margin: 1rem auto;
  }
  
  .desvEstandar {
      .generales-data{
        .heanding-mobile{
          width: 70%;
        }
        &:nth-of-type(even){
          background-color: $white;
          margin-bottom: 1rem;
          padding: 0 0.25rem;
        }
      }
  }

  .alert-success {
    padding: 2%;
  }
</style>